import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_SERVICES } from "../../../../common/data/WebApp";
import { Link } from "gatsby";
import ContactForm from "../../ContactForm/Section";

import React from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import SectionArea, { Row, ColumnContents } from "./section.style";
const Section = () => {
  const { sections } = SECTION_SERVICES;
  return (
    <ColumnContents>
      <SectionArea
        style={{ backgroundColor: "white" }}
        id="software-development"
      >
        <Container>
          <Row className="rowContents">
            <Box className="blockTitle">
              <Heading as="h2" content={sections[0].title} />
              <Text as="p" content={sections[0].text} />
            </Box>
            <Box className="postsWrap">
              <img src={sections[0].image} alt="x-rd" />
              {/* <StaticImage
              src="../images/logo-ascs.png"
              alt="australian cyber security centre"
              placeholder="blurred"
              objectFit="contain"
              objectPosition="center"
            /> */}
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <SectionArea
        style={{ backgroundColor: "#F4F4F4", paddingBottom: "60px" }}
      >
        <Container>
          <Row className="rowContents">
            <Box className="postsWrap">
              <img src={sections[1].image} alt="x-rd" />
              {/* <StaticImage
              src="../images/logo-ascs.png"
              alt="australian cyber security centre"
              placeholder="blurred"
              objectFit="contain"
              objectPosition="center"
            /> */}
            </Box>
            <Box className="blockTitle">
              <Heading as="h2" content={sections[1].title} />
              <Text as="p" content={sections[1].text} />
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <SectionArea style={{ backgroundColor: "white", paddingBottom: "60px" }}>
        <Container>
          <Row className="rowContents">
            <Box className="blockTitle">
              <Heading as="h2" content={sections[2].title} />
              <Text as="p" content={sections[2].text} />
            </Box>
            <Box className="postsWrap">
              <img src={sections[2].image} alt="x-rd" />
              {/* <StaticImage
              src="../images/logo-ascs.png"
              alt="australian cyber security centre"
              placeholder="blurred"
              objectFit="contain"
              objectPosition="center"
            /> */}
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <SectionArea
        style={{ backgroundColor: "#F4F4F4", paddingBottom: "60px" }}
      >
        <Container>
          <Row className="rowContents">
            <Box className="postsWrap">
              <img src={sections[3].image} alt="x-rd" />
              {/* <StaticImage
              src="../images/logo-ascs.png"
              alt="australian cyber security centre"
              placeholder="blurred"
              objectFit="contain"
              objectPosition="center"
            /> */}
            </Box>
            <Box className="blockTitle">
              <Heading as="h2" content={sections[3].title} />
              <Text as="p" content={sections[3].text} />
            </Box>
          </Row>
        </Container>
      </SectionArea>
      <ContactForm />
    </ColumnContents>
  );
};

export default Section;
